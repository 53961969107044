import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONSTANT } from '../../constants/application.constant';



@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpOptions1 = {
    headers: new HttpHeaders({
      observe : 'body'|| 'response',  
      responseType : 'text' as 'text',  
      Accept: 'text/plain, */*', 
      'Skip-Auth': 'true'  
    }),
  };

  constructor(private readonly http: HttpClient) { }

  getPDF(path: string, isAbsolutePath: boolean = false): Observable<any> {
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    let url;
    if (isAbsolutePath) {
      url = path;
    } else {
      url = `${APP_CONSTANT.BASE_URL}${path}`;
    }
    return this.http.get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }
  

  get(path: string, isAbsolutePath: boolean = false, skipAuthentication: boolean = false, useOHIDToken: boolean = false, isBlob: boolean = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Auth': skipAuthentication ? 'true' : 'false',
        'responseType': isBlob ? 'arraybuffer' as 'json' : 'json'
      }),
    }
    let url;
    if (isAbsolutePath) {
      url = path;
    } else {
      url = `${APP_CONSTANT.BASE_URL}${path}`;
    }
    return this.http.get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getUserInfo(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Auth': 'false',
        'OHID-Auth': 'true'
      }),
    }
    return this.http.get(`${APP_CONSTANT.BASE_URL}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getTestUserInfo(url: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Auth': 'true',
        'OHID-Auth': 'false'
      }),
    }
    return this.http.get(url, httpOptions)
      .pipe(catchError(this.handleError));
  }

  post(path: string, payload: any): Observable<any> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        observe : 'body'|| 'response',  
        responseType : 'text' as 'text',  
        Accept: 'text/plain, */*' || 'application/json',  
      })
    }
    // APP_CONSTANT.BASE_URL +
    return this.http
    .post( path, payload, httpOptions1)
    .pipe(catchError(this.handleError));
  }

  put(path: string, payload: any, skipAuthentication: boolean = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Auth': skipAuthentication ? 'true' : 'false'
      }),
    }
    return this.http.put(`${APP_CONSTANT.BASE_URL}${path}`, payload, httpOptions)
    .pipe(catchError(this.handleError));
  }

  authPost(path: string, payload: any, skipAuthentication: boolean = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Auth': skipAuthentication ? 'true' : 'false'
      }),
    }
    return this.http.post(`${APP_CONSTANT.BASE_URL}${path}`, payload, httpOptions)
    .pipe(catchError(this.handleError));
  }

  supportpost(path: string, payload: any): Observable<any> {
    return this.http
    .post(APP_CONSTANT.BASE_URL + path, payload, this.httpOptions1)
    .pipe(catchError(this.handleError));
  }

  postOrientationAttest(path: string, payload: any) : Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(APP_CONSTANT.BASE_URL + path, payload, { headers, responseType: 'text'})
    .pipe(catchError(this.handleError));
  }  

  authToken(url: string, payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Skip-Auth': 'true'
      }),
    };
    const params = new HttpParams({
      fromObject: payload
    });
    return this.http.post(url, params, httpOptions);
  }

  // Error handling
  private handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return {errorMessage, statusCode: error.status};
    });
  }
}
