import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private configObs$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  private updateConfig(show: boolean): void {
    const oNewConfig: any = {
      show
    }
    this.configObs$.next(oNewConfig);
  }

  getConfig(): Observable<any> {
    return this.configObs$.asObservable();
  }

  show(): void {
    this.updateConfig(true);
  }

  hide(): void {
    this.updateConfig(false);
  }
}
