import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { AuthInterceptor } from './interceptor/auth/auth.interceptor';
import { ProgressIndicatorModule } from '@ecp/angular-ui-component-library/progress-indicator';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HealthComponent } from './components/health/health.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackbarModule } from '@ecp/angular-ui-component-library/snackbar';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpinnerComponent,
    HealthComponent,
    SnackbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ProgressIndicatorModule,
    BrowserAnimationsModule,
    SnackbarModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF, useValue: environment.baseUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
