import { environment } from "../../environments/environment";

const BASE_URL = environment.api_base_url;

export const APP_CONSTANT = { 
    BASE_URL,
    Authorization_Code: 'authorization_code',
    Code_Verifier: 'code_verifier',
    Code_Challenge: 'code_challenge',
    STATE: 'state',
    Code: 'code',
    Refresh_Token: 'refresh_token',
    User_Info: 'user_info',
    Token: 'token',
    OHID_Token: 'ohid_token',
    Access_Token: 'access_token',
    Relogin: 'relogin',
    Id_Token: 'id_token',
    SHA_256: 'SHA-256',
    HYPHEN: '-',
    UNDERSCORE: '_',
    BLANK_STRING: '',
    SMALL_LETTERS: 'abcdefghijklmnopqrstuvwxyz',
    CAPITAL_LETTERS: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    NUMBERS: '0123456789',
    BOOLEAN: 'boolean',
    SPECIAL_CHARACTERS: '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\',
    Zero: 0
}

export const SpinnerDefaultConfig = {
    variant: 'secondary',
    spinnerSize: 'large',
    displayAs: 'overlay',
    headline: 'Wait a moment.',
    label: 'Please wait, we are processing your request. This will take a moment.',
    show: false
};

export const SnackbarDefaultConfig = {
    placement: 'top',
    variant: 'default',
    contentAlignment: 'left',
    elevation: 'large',
    contrast: 'dark',
    state: 'success',
    message: ''
}
