import { Injectable } from '@angular/core';
import { authConfig } from '../../configs/sso.config';
import { StorageService } from '../storage/storage.service';
import { HttpService } from '../http/http.service';
import { APP_CONSTANT } from '../../constants/application.constant';
import { Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(
    private readonly storageService: StorageService,
    private readonly httpService: HttpService,
    private route: Router,
    private readonly spinnerService: SpinnerService
  ) { }

  getAuthUrl(code_challenge: string, state: string = APP_CONSTANT.BLANK_STRING): string {
    const authUrl = `${authConfig.issuer}?client_id=${authConfig.clientId}&response_type=${authConfig.responseType}&redirect_uri=${authConfig.redirectUri}&code_challenge=${code_challenge}&code_challenge_method=S256&scope=openid+profile+email`;
    return state ? `${authUrl}&${APP_CONSTANT.STATE}=${state}` : authUrl;
  }

  getLogoutUrl(): string {
    const oToken = this.retrieveToken();
    if (oToken && oToken.id_token && oToken.access_token) {
      return `${authConfig.logoutUri}?id_token_hint=${oToken.id_token}&post_logout_redirect_uri=${authConfig.post_logout_redirect_uri}`;
    }
    return APP_CONSTANT.BLANK_STRING;
  }

  dec2hex(dec: any) {
    return ('0' + dec.toString(16)).substr(-2)
  }

  randomString() {
    let array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, this.dec2hex).join('');
  }

  base64urlencode(a: any) {
    let str = "";
    let bytes = new Uint8Array(a);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }


  async generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest(APP_CONSTANT.SHA_256, data);
    const base64Digest = this.base64urlencode(digest);
    return base64Digest;
  }

  getUserInfo() {
    return this.httpService.getUserInfo('v1.0/user/userinfo');
  }

  getstep2() {
    const path = environment.sso_path;
    return this.httpService.getUserInfo(path);
  }

  getAuthToken() {
    const payload = {
      grant_type: APP_CONSTANT.Authorization_Code,
      client_id: authConfig.clientId,
      code_verifier: this.storageService.retrieve(APP_CONSTANT.Code_Verifier),
      code: this.storageService.retrieve(APP_CONSTANT.Code),
      redirect_uri: authConfig.redirectUri
    }
    return this.httpService.authToken(authConfig.tokenEndpoint, payload);
  }

  retrieveToken(): any {
    const token = this.storageService.retrieve(APP_CONSTANT.Token);
    return token ? token : null;
  }

  async logout(activity: string = 'logout'): Promise<void> {
    this.ohidLogout();
    return;
  }

  ohidLogout(): void {
    this.spinnerService.show();
    const logoutUrl = this.getLogoutUrl();
    if (logoutUrl) {
      this.storageService.deleteAll();
      window.location.href = logoutUrl;
    } else {
      this.storageService.deleteAll();
      this.route.navigate(['/login']);
    }
    this.spinnerService.hide();
  }
}

