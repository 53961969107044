import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storageService: SessionStorageService,
  ) { }

  store(key: string, value: any): void {
    this.storageService.set(key, value);
  }

  retrieve(key: string): any {
    return this.storageService.get(key);
  }

  delete(key: string): void {
    this.storageService.remove(key);
  }

  deleteAll(): void {
    this.storageService.clear();
  }
}
