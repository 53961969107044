import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EcpUclSnackbar } from '@ecp/angular-ui-component-library/snackbar';

import { Subscription } from 'rxjs';
import { SnackbarDefaultConfig } from '../../constants/application.constant';
import { ISnackBarConfiguration } from '../../interfaces/education-and-resources/education-and-resources';
import { SnackbarService } from '../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  @ViewChild('snackbar') snackbar!: EcpUclSnackbar;
  oConfig: ISnackBarConfiguration = JSON.parse(JSON.stringify(SnackbarDefaultConfig));
  private subscriptions = new Subscription();
  constructor(
    private readonly snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig(): void {
    const updateConfigSubscription = this.snackbarService.getConfig().subscribe((res: ISnackBarConfiguration)=> {
      if (res) {
        this.oConfig = res;
        this.snackbar.state = this.oConfig.state;
        this.open();
      }
    });
    this.subscriptions.add(updateConfigSubscription);
  }

  open() {
    this.snackbar.openBar();
  }

  close() {
    this.snackbar.closeBar();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
