import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { StorageService } from '../../services/storage/storage.service';
import { APP_CONSTANT } from '../../constants/application.constant';
import { SsoService } from '../../services/sso/sso.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly storageService: StorageService,
    private readonly ssoService: SsoService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipAuth = (request.headers.get('Skip-Auth')  === 'true');
    const useOHIDToken = (request.headers.get('OHID-Auth')  === 'true');
    const tokenKey = request.headers.get('Token-Key') || APP_CONSTANT.Access_Token;
    request = request.clone({
      headers: request.headers.delete('Skip-Auth').delete('Token-Key').delete('OHID-Auth')
    });
    const token = JSON.parse(JSON.stringify(this.storageService.retrieve(APP_CONSTANT.Token)));
    if (!skipAuth) {
      if (token) {
        request = this.setTokenHeader(request, token, tokenKey, useOHIDToken);
      } else {
        this.ssoService.logout();
        return throwError(() => {
          return new Error('No token found');
        });
      }  
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.ssoService.ohidLogout();
          return throwError(() => {
            return {...err, message: 'Session timeout!' };
          });
        } else {
          return this.handleError(err);
        }
      })
    );
  }

  private setTokenHeader(request: HttpRequest<unknown>, token: any, tokenKey: string, useOHIDToken: boolean = false): HttpRequest<unknown> {
    if (useOHIDToken) {
      return request.clone({
        setHeaders: {
          authorization: `${token.token_type} ${token[tokenKey]}`,
          Authorization_OIDC: `${token[tokenKey]}`
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          authorization: `${token.token_type} ${token[tokenKey]}`
        }
      });
    }
  }

  private handleError(err: any, logout: boolean = false) {
    if (logout) {
      this.ssoService.logout();
    }
    return throwError(() => {
      return err;
    });
  }
}