import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APP_CONSTANT } from '../../constants/application.constant';
import { StorageService } from '../../services/storage/storage.service';
import { SsoService } from '../../services/sso/sso.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { SnackbarService } from '../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  showLoginPage: boolean = false;
  userInfo: any;
  private subscriptions = new Subscription()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private ssoService: SsoService,
    private readonly spinnerService: SpinnerService,
    private snackbarService: SnackbarService
  ) {
    this.navigationEvents();
  }

  navigationEvents(): void {
    const routerSubscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.storageService.store(APP_CONSTANT.Code, this.route.snapshot.queryParams[APP_CONSTANT.Code]);
          const code = this.storageService.retrieve(APP_CONSTANT.Code);
          this.storageService.delete(APP_CONSTANT.Code);
          const codeChallenge = this.storageService.retrieve(APP_CONSTANT.Code_Challenge);
          const token = this.ssoService.retrieveToken();
          if (!code && !codeChallenge && !token) {
            const relogin = this.storageService.retrieve(APP_CONSTANT.Relogin);
            if (relogin) {
              this.storageService.store(APP_CONSTANT.Relogin, false);
              this.loginPage();
            } else {
              this.showLoginPage = true;
              this.loginPage();
            }
          } else if (code) {
            this.storageService.store(APP_CONSTANT.Code, code);
            this.getAuthToken();
          } else if (codeChallenge && token) {
            this.getUserInfo();
          } else {
            this.ssoService.logout();
          }
        }
      }
    );
    this.subscriptions.add(routerSubscription);
  }

  loginPage() {
    const token = this.ssoService.retrieveToken();
    if (token) {
      this.getUserInfo();
    } else {
      const code_verifier = this.ssoService.randomString();
      this.ssoService.generateCodeChallenge(code_verifier).then(challenge => {
        const code_challenge = challenge;
        this.storageService.store(APP_CONSTANT.Code_Verifier, code_verifier);
        this.storageService.store(APP_CONSTANT.Code_Challenge, code_challenge);
        const authUrl = this.ssoService.getAuthUrl(code_challenge);
        window.location.href = authUrl;
      }).catch(err => {
        console.log(err);
        this.ssoService.logout();
        this.snackbarService.error(err?.errorMessage);
      });
    }
  }

  getAuthToken() {
    this.spinnerService.show();
    const apiSubscription = this.ssoService.getAuthToken().subscribe(
      {
        next: (res: any) => {
          const token = JSON.parse(JSON.stringify(res));
          this.storageService.store(APP_CONSTANT.Token, token);
          this.getUserInfo();
        },
        error: (e: any) => {
          console.log(e);
          this.snackbarService.error(e?.errorMessage);
          this.spinnerService.hide();
          this.ssoService.logout();
        }
      }
    );
    this.subscriptions.add(apiSubscription);
  }


  getUserInfo() {
    this.spinnerService.show();
    const apiSubscription = this.ssoService.getstep2().subscribe(
      {
        next: (res: any) => {
          const userInfo = JSON.parse(JSON.stringify(res));
          this.storageService.store(APP_CONSTANT.User_Info, userInfo);
          if (userInfo) {
            this.navigateProcessAfterLogin();
          } else {
            this.snackbarService.error('invalid_request');
            this.ssoService.ohidLogout();
            this.spinnerService.hide();
          }
        },
        error: (e: any) => {
          this.ssoService.ohidLogout();
          this.spinnerService.hide();
          this.snackbarService.error(e?.errorMessage);
        }
      }
    );
    this.subscriptions.add(apiSubscription);
  }


  navigateProcessAfterLogin() {
    this.spinnerService.hide();
    this.submitFormRequest();
  }


  submitFormRequest() {
    const userinfo = this.storageService.retrieve(APP_CONSTANT.User_Info)
    if (userinfo && userinfo.beseUrl && userinfo.landingUrl && userinfo.token) {
      const url = userinfo.beseUrl + userinfo.landingUrl;
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = url;
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'token';
      input.value = userinfo.token;
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    } else {
      if (userinfo) {
        this.snackbarService.error(userinfo.message);
      } else {
        this.snackbarService.error('Invalid request');
      }

    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
