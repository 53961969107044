import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { APP_CONSTANT } from '../../constants/application.constant';
import { EcpUclProgressIndicator } from '@ecp/angular-ui-component-library/progress-indicator';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('spinner') spinner!: EcpUclProgressIndicator;
  showSpinner: boolean = false;
  private subscriptions = new Subscription();

  constructor(
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.getConfig();
  }

  getConfig(): void {
    const updateConfigSubscription = this.spinnerService.getConfig().subscribe((res)=> {
      if (res && (typeof res.show == APP_CONSTANT.BOOLEAN) && (res.show !== this.showSpinner)) {
        this.showSpinner = res.show;
      }
    });
    this.subscriptions.add(updateConfigSubscription);
  }

  show(): void {
    if (this.showSpinner) {
      this.spinner.openProgressCirclePopup();
    }
  }

  hide(): void {
    this.spinner.openProgressCirclePopup();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
