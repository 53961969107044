
<div *ngIf="showSpinner">
  <ecp-ucl-progress-indicator 
    color="green"
    speed="2s"
    progressType="bar"
    [showValue]="false"
    variant="primary"
    mode="indeterminate"
    displayAs="in-line"
    spinnerSize="medium"
    headline="Wait a moment."
    label="Information is waiting to load."
    popupPosition="center"  
    parentWidth="100px" 
  >
  </ecp-ucl-progress-indicator>

  <!-- <ecp-ucl-progress-indicator 
    color="green"
    speed="2s"
    progressType="circle"
    [showValue]="false"
    [value]="33"
    variant="primary"
    mode="indeterminate"
    displayAs="in-line"
    spinnerSize="medium"
    popupPosition="top"  
    parentWidth="300px" 
  >
  </ecp-ucl-progress-indicator> -->
</div>
