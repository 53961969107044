import { Injectable } from '@angular/core';
import { StateType } from '@ecp/angular-ui-component-library/snackbar';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackbarDefaultConfig } from '../../constants/application.constant';
import { ISnackBarConfiguration } from '../../interfaces/education-and-resources/education-and-resources';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private oDefaultConfig: ISnackBarConfiguration = JSON.parse(JSON.stringify(SnackbarDefaultConfig));
  private configObs$: BehaviorSubject<ISnackBarConfiguration> = new BehaviorSubject<any>(null);

  constructor(
  ) { }

  show(message: string, state: StateType) {
    const oNewConfig: ISnackBarConfiguration = {
      ...JSON.parse(JSON.stringify(this.oDefaultConfig)),
      state,
      message
    }
    this.configObs$.next(oNewConfig);
  }

  success(message: string) {
    this.show(message, 'success')
  }

  error(message: string) {
    this.show(message, 'error')
  }

  info(message: string) {
    this.show(message, 'info')
  }

  warning(message: string) {
    this.show(message, 'warning')
  }

  getConfig(): Observable<ISnackBarConfiguration> {
    return this.configObs$.asObservable();
  }
}
