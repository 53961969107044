export const environment = {
    baseUrl: '/',
    api_base_url: 'https://gateway-stage.optum.com/api/nonprod/clin/',
    redirectUri: 'https://pathway-drfirst-nonprod.optum.com/login',
    post_logout_redirect_uri: 'https://pathway-drfirst-nonprod.optum.com/login',
    sso_path: 'pathway/v1.0',
    issuer: 'https://identity.nonprod.onehealthcareid.com/oidc/authorize',
    logoutUri: 'https://identity.nonprod.onehealthcareid.com/oidc/logout',
    tokenEndpoint: 'https://identity.nonprod.onehealthcareid.com/oidc/token'
}
