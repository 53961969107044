import { environment } from '../../environments/environment';

export const authConfig = {

  // Url of the Identity Provider
  //issuer: 'https://steyer-identity-server.azurewebsites.net/identity',
  issuer: 'https://identity.nonprod.onehealthcareid.com/oidc/authorize',

  // URL of the SPA to redirect the user to after login
  redirectUri: environment.redirectUri,

  tokenEndpoint: 'https://identity.nonprod.onehealthcareid.com/oidc/token',


  logoutUri: 'https://identity.nonprod.onehealthcareid.com/oidc/logout',
  post_logout_redirect_uri: environment.post_logout_redirect_uri,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  //clientId: 'spa-demo',
  clientId: 'CCMEP016268N',

  showDebugInformation: true,
  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email',
}
